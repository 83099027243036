
<script>
// Extensions
import View from '@/views/View';

// Mixins
import LoadSections from '@/mixins/load-sections';

export default {
    name: 'Home',

    metaInfo: { title: 'Product Categories' },

    meta: [
        {
            name: 'keywords',
            content:
                'food chemicals, Solvents, Industrial chemicals, Dextrose monohydrate, citric acid, Monosodium glutamate, sodium benzoate, calcium hypochlorite, Hydrated lime, Allumium sulphate, Ethyl alcohol (Ethanol), Food flavours, caustic soda, Industrial chemicals, Calcium hypochlorite (Chlorine), Hydrated Lime, Alluminium sulphate, Ethyl alcohol,Ethanol,Quicklime ,Soda ash, Boric acid , Borax acid, Hydrochloric acid, Phosphoric acid, Acetic acid, Sulphuric acid , Sulphonic acid, Hydrochloric acid, Hydrogen peroxide, Sodium hypochlorite, Glycerin, Formaldehyde, Sodium benzoate, Citric acid anhydrous, Citric acid monohydrate, Potassium sorbate, Dextrose monohydrate, Maltodextrin , Sodium bicarbonate, Sorbic acid, Calcium propionate, Sodium citrate, Lactic acid, Malic acid, Aspartame, Acesulfame-k, Xanthan gum, Guar gum, Maize/corn starch, Glycerin monostearate, Monosodium glutamate',
        },
        {
            name: 'description',
            content:
                'Sylvaleo Global - Home of Solvents, Industrial and Food Chemicals',
        },
        {
            property: 'og:title',
            content:
                'Sylvaleo Global - Home of Solvents Industrial and Food Chemicals',
        },
        { property: 'og:site_name', content: 'Sylvaleo Global' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
    ],

    extends: View,

    mixins: [LoadSections(['services', 'keep-in-touch'])],

    props: {
        id: {
            type: String,
            default: 'home',
        },
    },
};
</script>
